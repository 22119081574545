.mask1 {
  background: url("../../assets/images/hero-section/mask2.jpeg");
  background-size: contain;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mask2 {
  background: url("../../assets/images/hero-section/mask2.jpeg");
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.get-started-btn {
  background-color: #030304;
  color: #f1f1f1;
}
.get-started-container {
  background-color: #030304;
  color: #f5f6ff;
}
.try-now-btn {
  background-color: #4d54ff;
  color: #f1f1f1;
}
.developer-feature-card {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
}

.animated-box {
  /* width: 200px; */
  height: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  animation: upDown 2s ease-in-out infinite;
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Moves up */
  }
  100% {
    transform: translateY(0); /* Moves back down */
  }
}
