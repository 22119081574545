body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cursore_pointer {
  cursor: pointer;
}
.primary-color {
  color: #030304;
}
.color_5A5A5A {
  color: #5a5a5a;
}
.bg_f9f9f9 {
  background-color: #f9f9f9;
}
.bg_F1F1F1 {
  background-color: #fdfdff;
}
.bg_F5F6FF {
  background-color: #f5f6ff;
}
.color_7A7A7A {
  color: #7a7a7a;
}
.color_F24445 {
  color: #f24445;
}
.color_4D54FF {
  color: #4d54ff;
}
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}
.fw_700 {
  font-weight: 700;
}
.fw_800 {
  font-weight: 700;
}
.fw_900 {
  font-weight: 700;
}
.fs_10 {
  font-size: 10px;
}

.fs_11 {
  font-size: 11px;
}

.fs_12 {
  font-size: 12px;
}

.fs_13 {
  font-size: 13px;
}

.fs_14 {
  font-size: 14px;
}

.fs_15 {
  font-size: 15px;
}

.fs_16 {
  font-size: 16px;
}

.fs_17 {
  font-size: 17px;
}

.fs_18 {
  font-size: 18px;
}

.fs_18 {
  font-size: 19px;
}

.fs_20 {
  font-size: 20px;
}

.fs_21 {
  font-size: 21px;
}

.fs_22 {
  font-size: 22px;
}

.fs_23 {
  font-size: 23px;
}

.fs_24 {
  font-size: 24px;
}

.fs_25 {
  font-size: 25px;
}

.fs_26 {
  font-size: 26px;
}

.fs_27 {
  font-size: 27px;
}

.fs_28 {
  font-size: 28px;
}

.fs_29 {
  font-size: 29px;
}

.fs_30 {
  font-size: 30px;
}

.fs_31 {
  font-size: 31px;
}

.fs_32 {
  font-size: 32px;
}

.fs_33 {
  font-size: 33px;
}

.fs_34 {
  font-size: 34px;
}

.fs_35 {
  font-size: 35px;
}

.fs_38 {
  font-size: 38px;
}

.fs_40 {
  font-size: 40px;
}
.fs_50 {
  font-size: 50px;
}
.fs_58 {
  font-size: 58px;
}
.fs_78 {
  font-size: 78px;
}
@media only screen and (max-width: 1200px) {
  .fs_lg_10 {
    font-size: 10px;
  }

  .fs_lg_11 {
    font-size: 11px;
  }

  .fs_lg_12 {
    font-size: 12px;
  }

  .fs_lg_13 {
    font-size: 13px;
  }
  .fs_lg_14 {
    font-size: 14px;
  }
  .fs_lg_16 {
    font-size: 16px;
  }
  .fs_lg_20 {
    font-size: 20px;
  }
  .fs_lg_22 {
    font-size: 22px;
  }
  .fs_lg_24 {
    font-size: 24px;
  }
  .fs_lg_26 {
    font-size: 26px;
  }
  .fs_lg_28 {
    font-size: 28px;
  }
  .fs_lg_38 {
    font-size: 38px;
  }
  .fs_lg_50 {
    font-size: 50px;
  }
  .fs_lg_58 {
    font-size: 58px;
  }
}
@media only screen and (max-width: 992px) {
  .fs_md_10 {
    font-size: 10px;
  }

  .fs_md_11 {
    font-size: 11px;
  }

  .fs_md_12 {
    font-size: 12px;
  }

  .fs_md_13 {
    font-size: 13px;
  }
  .fs_md_14 {
    font-size: 14px;
  }
  .fs_md_16 {
    font-size: 16px;
  }
  .fs_md_18 {
    font-size: 18px;
  }
  .fs_md_20 {
    font-size: 20px;
  }
  .fs_md_22 {
    font-size: 22px;
  }
  .fs_md_24 {
    font-size: 24px;
  }
  .fs_md_26 {
    font-size: 26px;
  }
  .fs_md_28 {
    font-size: 28px;
  }
  .fs_md_32 {
    font-size: 32px;
  }
  .fs_md_38 {
    font-size: 38px;
  }
  .fs_md_40 {
    font-size: 40px;
  }
  .fs_md_50 {
    font-size: 50px;
  }
  .fs_md_58 {
    font-size: 58px;
  }
}
@media only screen and (max-width: 768px) {
  .fs_sm_10 {
    font-size: 10px;
  }

  .fs_sm_11 {
    font-size: 11px;
  }

  .fs_sm_12 {
    font-size: 12px;
  }

  .fs_sm_13 {
    font-size: 13px;
  }

  .fs_sm_14 {
    font-size: 14px;
  }

  .fs_sm_15 {
    font-size: 15px;
  }

  .fs_sm_16 {
    font-size: 16px;
  }

  .fs_sm_17 {
    font-size: 17px;
  }

  .fs_sm_18 {
    font-size: 18px;
  }

  .fs_sm_19 {
    font-size: 19px;
  }

  .fs_sm_20 {
    font-size: 20px;
  }

  .fs_sm_21 {
    font-size: 21px;
  }

  .fs_sm_22 {
    font-size: 22px;
  }

  .fs_sm_23 {
    font-size: 23px;
  }

  .fs_sm_24 {
    font-size: 24px;
  }

  .fs_sm_25 {
    font-size: 25px;
  }

  .fs_sm_26 {
    font-size: 26px;
  }

  .fs_sm_28 {
    font-size: 28px;
  }

  .fs_sm_29 {
    font-size: 29px;
  }

  .fs_sm_30 {
    font-size: 30px;
  }

  .fs_sm_31 {
    font-size: 31px;
  }

  .fs_sm_32 {
    font-size: 32px;
  }

  .fs_sm_33 {
    font-size: 33px;
  }

  .fs_sm_34 {
    font-size: 34px;
  }

  .fs_sm_35 {
    font-size: 35px;
  }

  .fs_sm_40 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 576px) {
  .fs_xs_10 {
    font-size: 10px;
  }

  .fs_xs_11 {
    font-size: 11px;
  }

  .fs_xs_12 {
    font-size: 12px;
  }

  .fs_xs_13 {
    font-size: 13px;
  }

  .fs_xs_14 {
    font-size: 14px;
  }

  .fs_xs_15 {
    font-size: 15px;
  }

  .fs_xs_16 {
    font-size: 16px;
  }

  .fs_xs_17 {
    font-size: 17px;
  }

  .fs_xs_18 {
    font-size: 18px;
  }

  .fs_xs_19 {
    font-size: 19px;
  }

  .fs_xs_20 {
    font-size: 20px;
  }

  .fs_xs_21 {
    font-size: 21px;
  }

  .fs_xs_22 {
    font-size: 22px;
  }

  .fs_xs_23 {
    font-size: 23px;
  }

  .fs_xs_24 {
    font-size: 24px;
  }

  .fs_xs_25 {
    font-size: 25px;
  }

  .fs_xs_26 {
    font-size: 26px;
  }

  .fs_xs_27 {
    font-size: 27px;
  }

  .fs_xs_28 {
    font-size: 28px;
  }

  .fs_xs_29 {
    font-size: 29px;
  }

  .fs_xs_30 {
    font-size: 30px;
  }

  .fs_xs_31 {
    font-size: 31px;
  }

  .fs_xs_32 {
    font-size: 32px;
  }

  .fs_xs_33 {
    font-size: 33px;
  }

  .fs_xs_34 {
    font-size: 34px;
  }

  .fs_xs_35 {
    font-size: 35px;
  }

  .fs_xs_38 {
    font-size: 38px;
  }

  .fs_xs_40 {
    font-size: 40px;
  }
}
