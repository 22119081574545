.contact-input {
  border: 0;
  font-size: 16px;
  background-color: #f5f6ff;
}
.contact-input::placeholder {
  color: #adadad;
  font-size: 16px;
  background-color: #f5f6ff;
}
.contact-input:focus-visible {
  outline: none;
}
